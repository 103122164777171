import React from 'react';
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    withStyles
} from '@material-ui/core';
import ClearIcon from "@material-ui/icons/Clear";
import AnyField from "../fields/AnyField";
import _ from "lodash";
import {defaultCurrency, formatMoney} from "../../services/common";
import * as PropTypes from "prop-types";
import cn from 'classnames';
import {getPayDocsByGuest, payDocMeasures, payDocStatuses, payDocTypeCodes} from "../../services/payDocs";
import ActionButton from "../button/ActionButton";
import {connect} from "react-redux";
import messageDialogActions from "../dialogs/messageDialog-acions";
import {
    getSale,
    paymentStatus,
    emptyPay,
    savePayment,
    salePayTypes,
    optionalSalePayTypes,
    calculateCashChange, deletePayment, saleStatus, sendPaymentLink
} from "../../services/sale";
import MoneyField from "../fields/MoneyField";
import {withTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import {getBusinessUnitByRole, hasRole} from "../../services/user";
import {Link} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import ForwardIcon from "@material-ui/icons/Forward";
import moment from "moment";
import {getWorkShiftCurrentBusinessUnit} from "../../services/calendarEmployee";
import SelectFromItemsField from "../fields/SelectFromItemsField";
import CopyField from "../fields/CopyField";
import MultiSelectFromItemsField from "../fields/MultiSelectFromItemsField";
import SendIcon from '@material-ui/icons/Send';
import infoActions from "../info/info-actions";
import NumberFormatDecimal from "../fields/NumberFormatDecimal";
import GuestField from "../fields/GuestField";
import DateField2 from "../fields/DateField2";
import {getCheckingAccounts, getJuridicalPersons} from "../../services/organization";
import {askPinRuleType} from "../../entities/enums/askPinRule";
import PayDocAskPinDialog from "../../pages/pay-docs/PayDocAskPinDialog";

const styles = theme => ({
    container: {
        minWidth: 600,
    },
    restTitle: {
        marginLeft:  theme.spacing(3),
        color: theme.palette.text.secondary,
    },
    endAdornment: {
        position: 'absolute',
        right: 0,
    },
    inputPD: {
        paddingRight: 20,
    },
    notValidPayDoc: {
        color: 'red',
    },
    restError: {
        color: 'red',
    },
    deletePayIcon: {
        paddingTop:"24px",
        paddingRight:"10px"
    }
});

export default
@connect(null, {
    showMessage: messageDialogActions.show,
    showInfo: infoActions.show,
})
@withStyles(styles)
@withTranslation()
class Pay extends React.Component
{
    static propTypes = {
        className: PropTypes.string,
        guestId: PropTypes.string,
        amountTotal: PropTypes.number,
        onChange: PropTypes.func,
        buttonLabel: PropTypes.any,
        saleId: PropTypes.string,
        availablePaymentTypes: PropTypes.array,
        refreshSale: PropTypes.bool,
        disabled: PropTypes.bool,
        possibilityPayment: PropTypes.bool,
        disablePayDocMeasures: PropTypes.array,
        disablePayDocVariants: PropTypes.array,
        disablePaymentLinks: PropTypes.bool,
        onlyPaymentLinks: PropTypes.bool,
        customBonusPayDocGuest: PropTypes.bool,
        filterPayDocsWithValidityBefore: PropTypes.any,
        enableCheckingAccountPaymentType: PropTypes.bool,
        filterPaymentForGoodsAndBalance: PropTypes.bool,
    };

    static defaultProps = {
        buttonLabel: 'Добавить способ оплаты',
        availablePaymentTypes: _.values(salePayTypes),
        refreshSale: false,
        disabled: false,
        possibilityPayment: false,
        disablePayDocMeasures: [],
        disablePayDocVariants: [],
        disablePaymentLinks: false,
        onlyPaymentLinks: false,
        customBonusPayDocGuest: false,
        filterPayDocsWithValidityBefore: null,
        enableCheckingAccountPaymentType: false,
        filterPaymentForGoodsAndBalance: false
    };

    state = {
        payDocs: [],
        processing: false,
        sale: {
            id: null,
            payments: [],
            usingCurrencies: [],
            totalMultiCurrencies: {},
            restMultiCurrencies: {},
            restMultiCurrenciesError: null,
            guestId: null,
            paymentBusinessUnitId: null,
            hasPaymentLinks: false,
            businessUnitBonusesEnabled: false,
            businessUnitBonusPaymentMaxPercent: 0
        },
        loadingCashChange: false,
        loadingSale: false,
        businessUnits: [],
        currentBusinessUnitId: null,
        showPaymentBusinessUnitModal: false,
        handleSavePaymentArguments: [],

        juridicalPersons: [],
        checkingAccounts: [],

        askPinDialogOpen: false,
        askPinDialogIdx: null,
    };

    componentDidMount() {
        this.loadPayDocs(this.props.guestId);
        this.loadSale(this.props.saleId);

        getBusinessUnitByRole('ROLE_SALE_PAYMENT_WITHOUT_CURRENT_PARLOUR')
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });

        getWorkShiftCurrentBusinessUnit()
            .then(response => {
                if (response.success) {
                    this.setState({
                        currentBusinessUnitId: response.data.id
                    });
                }
            });

        if (this.props.enableCheckingAccountPaymentType) {
            getJuridicalPersons()
                .then(response => {
                    if (response.success) {
                        this.setState({
                            juridicalPersons: response.data,
                        })
                    }
                });

            getCheckingAccounts({active: true})
                .then(response => {
                    if (response.success) {
                        this.setState({
                            checkingAccounts: response.data,
                        })
                    }
                });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const { sale } = this.state;

        if (nextProps.guestId !== this.props.guestId) {
            this.loadPayDocs(nextProps.guestId);
        }

        if (nextProps.saleId !== this.props.saleId || nextProps.refreshSale !== this.props.refreshSale) {
            this.loadSale(nextProps.saleId);
        }

        if (nextProps.amountTotal !== this.props.amountTotal) {
            const amountRest = this.getCurrentRest(nextProps.amountTotal);

            if (this.props.onChange) {
                this.props.onChange(sale.payments, amountRest);
            }
        }

        if (this.props.businessUnitId) {
            this.setState({
                sale: {
                    ...sale,
                    businessUnitId: this.props.businessUnitId,
                    businessUnitName: this.props.businessUnitName
                }
            });
        }
    }

    loadPayDocs = guestId => {
        const { sale } = this.state;
        if (guestId || (sale && sale.guestId)) {
            getPayDocsByGuest(guestId || sale.guestId, sale.id)
                .then(response => {
                    if (response.success) {
                        let payDocs = response.data;

                        if (this.props.disablePayDocVariants.length) {
                            payDocs = payDocs.filter((payDoc) => this.props.disablePayDocVariants.indexOf( payDoc.type.variant ) === -1);
                        }

                        if (this.props.disablePayDocMeasures.length) {
                            payDocs = payDocs.filter((payDoc) => this.props.disablePayDocMeasures.indexOf( payDoc.type.measure ) === -1);
                        }

                        if (this.props.filterPayDocsWithValidityBefore) {
                            payDocs = payDocs.filter(
                                pd => moment(pd.validity) >= (moment(this.props.filterPayDocsWithValidityBefore))
                            )
                        }

                        if (this.props.filterPaymentForGoodsAndBalance) {
                            payDocs = payDocs.filter(pd => pd?.type?.paymentForGoods && pd?.balance > 0)
                        }


                        this.setState({
                            payDocs: payDocs,
                        });
                    }
                })

        } else {
            this.setState({
                payDocs: [],
            })
        }
    };

    loadSale = saleId => {
        if (saleId) {
            this.setState({ loadingSale: true });
            getSale(saleId)
                .then(response => {
                    if (response.success) {
                        let sale = response.data;

                        if (this.props.paymentType) {
                            let sumPay = 0;
                            for (let item in response.data.restMultiCurrencies) {
                                sumPay += response.data.restMultiCurrencies[item].val || response.data.restMultiCurrencies[item];
                            }

                            if (sumPay > 0 ) {
                                let pay = {
                                    ...emptyPay,
                                    type: this.props.paymentType,
                                };

                                if (this.props.paymentType === salePayTypes.cash) {
                                    pay.amountCash = {...sale.total};
                                    pay.amountCashChange = {val: 0, cur: sale.total.cur};
                                }

                                sale = {
                                    ...sale,
                                    payments: [{...pay}]
                                }
                            }
                        }

                        this.setState({
                            sale: {
                                ...sale,
                                payments: sale.payments.map(p => ({
                                    ...p,
                                    juridicalPersonId: this.getJuridicalPersonId(p.checkingAccountId)
                                }))
                            },
                            loadingSale: false
                        }, () => {
                            this.loadPayDocs(this.props.guestId);
                            this.blockCancel();
                        });

                        if (this.props.onChange) {
                            this.props.onChange(sale.payments, this.getCurrentRest(sale.payments, this.props.amountTotal));
                        }
                    } else {
                        this.setState({ loadingSale: false });
                    }
                });
        }
    };

    getCurrentRest = (payments, amountTotal) => {
        const { restMultiCurrencies } = this.state.sale;
        return restMultiCurrencies[this.state.sale.mainCurrency] ? restMultiCurrencies[this.state.sale.mainCurrency].val : amountTotal;
    };

    updateCashChange = (paymentIdx, amount) => {
        const { sale } = this.state;

        if (amount.val > 0) {
            this.setState({
                loadingCashChange: true,
            });
            calculateCashChange(sale.businessUnitId, amount)
                .then(response => {
                    const { sale } = this.state;
                    let payments  = [...sale.payments];
                    payments[paymentIdx] = {
                        ...payments[paymentIdx],
                        amountCashChange: response.success ? response.data.amount : {val: 0, cur: amount.cur},
                    };
                    this.setState({
                        sale: {
                            ...sale,
                            payments,
                        },
                        loadingCashChange: false,
                    });
                })
        } else {
            let payments  = [...sale.payments];
            payments[paymentIdx] = {
                ...payments[paymentIdx],
                amountCashChange: {val: 0, cur: amount.cur}
            };
            this.setState({
                sale: {
                    ...sale,
                    payments,
                }
            });
        }
    };

    debounceUpdateCashChange = _.debounce(this.updateCashChange, 300);

    showCurrentRest = () => {
        const { t, classes } = this.props;
        const { restMultiCurrencies, restMultiCurrenciesError } = this.state.sale;

        if (restMultiCurrenciesError) {
            return <span className={classes.restError}>{restMultiCurrenciesError}</span>
        }

        const out = [];
        for (let rest in restMultiCurrencies) {
            if (rest === 'PDTime' || rest === 'PDMoney') {
                continue;
            }
            out.push( formatMoney(restMultiCurrencies[rest]) );
        }

        if (restMultiCurrencies && restMultiCurrencies.PDMoney !== null && restMultiCurrencies.PDTime !== undefined && Object.keys(restMultiCurrencies.PDMoney).length) {
            out.push( t('pay.pd') + ' ' + formatMoney( restMultiCurrencies.PDMoney ) );
        }

        if (restMultiCurrencies && restMultiCurrencies.PDTime !== null && restMultiCurrencies.PDTime !== undefined) {
            out.push( t('pay.pd') + ' ' + restMultiCurrencies.PDTime.toLocaleString() + ' ' + t('pay.h') );
        }

        return <React.Fragment>{out.join(' / ')}</React.Fragment>;
    };

    handleChangePay = (prop, idx) => event => {
        const { amountTotal } = this.props;
        const { sale } = this.state;
        let newValue = event.target.value;

        let payments = [...sale.payments];
        const oldPayment = payments[idx];
        if (prop === 'amount' && oldPayment.amount && oldPayment.amount.cur !== newValue.cur) {
            const oldRest = sale.restMultiCurrencies ? sale.restMultiCurrencies[oldPayment.amount.cur] : null;
            const newRest = sale.restMultiCurrencies ? sale.restMultiCurrencies[newValue.cur] : null;

            if (oldRest && newRest && oldRest.val === parseFloat(oldPayment.amount.val)) {
                newValue.val = newRest.val;
            }
        }

        if (prop === 'amount' && oldPayment.amountCash) {
            oldPayment.amountCash.cur = newValue.cur;
        }

        if (prop === 'juridicalPersonId' && oldPayment.checkingAccountId) {
            oldPayment.checkingAccountId = null;
        }

        payments[idx] = {
            ...oldPayment,
            [prop]: newValue
        };

        if (payments[idx].type === salePayTypes.cash && (prop === 'amount' || prop === 'amountCash')) {
            const cashChange = {
                val: (payments[idx].amountCash ? payments[idx].amountCash.val : 0) - payments[idx].amount.val,
                cur: payments[idx].amount.cur
            };
            this.debounceUpdateCashChange(idx, cashChange);
        }

        if (prop === "payDoc"){
            if (this.isPaymentWithBonusTypePayDoc(payments[idx])) {
                payments[idx].amount.val = this.calculateBonusPaymentMaxAmount(payments[idx])
            }
        }

        this.setState({
            sale: {
                ...sale,
                payments,
            }
        }, () => {
            if (prop === 'type' && newValue !== oldPayment.type) {
                this.handleChangePay('amount', idx)({ target: { value: { ...oldPayment.amount, val: null}}});
            }
        });

        if (this.props.onChange) {
            this.props.onChange(payments, this.getCurrentRest(payments, amountTotal));
        }
    };

    isPinRequired(pay, idx) {
        if (pay.type !== salePayTypes.paydoc) {
            return false;
        }

        const payment = this.state.sale.payments[idx];

        if (payment.type !== 'paydoc') { return false; }

        const payDoc = this.state.payDocs.find((payDoc) => payDoc.id === payment.payDoc);

        if (typeof payDoc === 'undefined') { return false; }

        return payDoc.type.pin.includes(askPinRuleType.ON_MONEY);
    }

    isPaymentWithBonusTypePayDoc = (payment) => {
        return payment.type === "paydoc"
            && payment.payDoc
            && this.state.payDocs.find((payDoc) => payDoc.id === payment.payDoc)
            && this.state.payDocs.find((payDoc) => payDoc.id === payment.payDoc).type.code === payDocTypeCodes.bonuses
    }

    calculateBonusPaymentMaxAmount = (payment) => {
        const { businessUnitBonusPaymentMaxPercent } = this.state.sale
        const { amountTotal } = this.props
        const { payDocs } = this.state
        const payDocBalance = payDocs.find((payDoc) => payDoc.id === payment.payDoc).balance
        const bonusPaymentMaxAmount = amountTotal * businessUnitBonusPaymentMaxPercent

        return bonusPaymentMaxAmount < payDocBalance ? bonusPaymentMaxAmount : payDocBalance
    }

    blockCancel = () => {
        const { sale } = this.state;

        if (this.props.blockCancel && sale.payments && Array.isArray(sale.payments)) {
            sale.payments.forEach(pay => {
                if (this.props.paymentType && pay.status === paymentStatus.paid) {
                    this.props.blockCancel(true);
                }
            })
        }
    }

    handleAddPay = () => {
        const { amountTotal } = this.props;
        const { sale } = this.state;

        const payments = [...sale.payments, _.cloneDeep(emptyPay) ];

        this.setState({
            sale: {
                ...sale,
                payments,
            }
        });

        if (this.props.onChange) {
            this.props.onChange(payments, this.getCurrentRest(payments, amountTotal));
        }
    };

    handleDelPay = idx => () => {
        const { amountTotal } = this.props;
        const { sale } = this.state;
        let payments  = [...sale.payments];

        if (payments[idx].id) {

            this.setState({
                processing: true
            });

            deletePayment(payments[idx].id)
                .then(response => {
                    if (response.success) {
                        const sale = response.data;

                        this.setState({
                            sale,
                        }, this.blockCancel);

                        if (this.props.onChange) {
                            this.props.onChange(sale.payments, this.getCurrentRest(sale.payments, this.props.amountTotal));
                        }

                        this.loadPayDocs(this.props.guestId);
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }

                    this.setState({
                        processing: false
                    });
                });

        } else {
            _.remove(payments, (pay, index) => index === idx);

            this.setState({
                sale: {
                    ...sale,
                    payments,
                }
            }, this.blockCancel);

            if (this.props.onChange) {
                this.props.onChange(payments, this.getCurrentRest(payments, amountTotal));
            }
        }

    };

    sendPaymentLink = (idx) => () => {
        const { sale } = this.state;
        const { t } = this.props;
        let payments  = [...sale.payments];
        let payment = payments[idx];
        if (!payment.sendToMessengers.length) {
            this.props.showInfo(t('pay.noMessengerChoose'));
            return;
        }
        sendPaymentLink(payment.id, [...payment.sendToMessengers])
            .then(response => {
                if (response.success) {
                    this.props.showInfo(t('pay.publicLinkSent'));
                    payments[idx].sendToMessengers = [];
                    this.setState({
                       sale: {
                           ...sale,
                           payments: payments
                       }
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });

    };

    handleAskPinDialogClosed = (ok, pin) => () => {
        const {sale, askPinDialogIdx} = this.state;

        sale.payments[askPinDialogIdx].pin = pin;

        this.setState({
            askPinDialogOpen: false,
            askPinDialogIdx: null,
        });

        if (ok) {
            this.setState({
                sale: sale,
            });
            this.handleSavePayment(askPinDialogIdx)();
        }
    };

    handlePayPayment = (idx, pay) => () => {
        if (this.isPinRequired(pay, idx)) {
            this.setState({
                askPinDialogOpen: true,
                askPinDialogIdx: idx,
            });
            return;
        }
        this.handleSavePayment(idx)();
    };

    handleSavePayment = (idx, status) => () => {
        const { sale, businessUnits } = this.state;

        const payments = _.cloneDeep(sale.payments);

        payments[idx].status = status || paymentStatus.paid;

        this.checkFreezyAlert(payments[idx]);

        if (payments[idx].type !== salePayTypes.paydoc && hasRole('ROLE_SALE_PAYMENT_WITHOUT_CURRENT_PARLOUR') && !this.state.currentBusinessUnitId && !sale.paymentBusinessUnitId) {
            let paymentBusinessUnit = businessUnits.find( businessUnit => businessUnit.id === sale.businessUnitId);

            this.setState({
                showPaymentBusinessUnitModal: true,
                handleSavePaymentArguments: [idx, status],
                sale: {
                    ...sale,
                    paymentBusinessUnitId: paymentBusinessUnit ? paymentBusinessUnit.id : null
                }
            });
            return;
        }

        this.setState({
            processing: true
        });

        savePayment({...sale, payments})
            .then(response => {
                if (response.success) {
                    const sale = response.data;

                    this.setState({
                        sale: {
                            ...sale,
                            payments: sale.payments.map(p => ({
                                ...p,
                                juridicalPersonId: this.getJuridicalPersonId(p.checkingAccountId)
                            }))
                        }
                    }, this.blockCancel);

                    if (this.props.onChange) {
                        this.props.onChange(sale.payments, this.getCurrentRest(sale.payments, this.props.amountTotal));
                    }
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }

                this.loadPayDocs(this.props.guestId);

                this.setState({
                    processing: false
                });
            });
    };

    checkFreezyAlert = (payment) => {
        if (payment.status !== paymentStatus.frozen) {
            return;
        }

        if (!_.isEqual(this.state.sale.usingCurrencies, [defaultCurrency])) {
            // TODO: Multi currency not supported.
            return;
        }

        const payDoc = this.state.payDocs.find((payDoc) => payDoc.id === payment.payDoc);

        const currentRest = this.getCurrentRest();
        const currentAmount = _.sumBy(this.state.sale.payments, 'amount.val') ?? 0;
        const payDocsFree = payDoc.balance - payment.amount.val;

        if (currentRest > currentAmount) {
            const money = formatMoney({val: currentRest - currentAmount, cur: defaultCurrency});

            this.props.showMessage(`Сумма ${money} потребуется доплатить гостю в салоне. Требуется озвучить гостю.`);
        } else if (payDocsFree > 0) {
            const money = formatMoney({val: payDocsFree, cur: defaultCurrency});

            this.props.showMessage(`Сумма ${money} останется на сертификате. Предложить дополнительные услуги.`);
        }
    }

    getJuridicalPersonId = (checkingAccountId) => {
        const checkingAccount = this.state.checkingAccounts.find(ca => ca.id === checkingAccountId)

        if (checkingAccount) {
            return checkingAccount.juridicalPersonId
        }
        return null;
    }

    render() {
        const {
            classes,
            className,
            amountTotal,
            buttonLabel,
            availablePaymentTypes,
            t,
            disabled,
            possibilityPayment,
            onlyPaymentLinks,
            disablePaymentLinks,
            guestId,
            enableCheckingAccountPaymentType
        } = this.props;
        const { payDocs, sale, processing, loadingCashChange, loadingSale, checkingAccounts, juridicalPersons, askPinDialogOpen } = this.state;

        const amountRest = this.getCurrentRest(sale.payments, amountTotal);

        const disableAdd =
            !(sale && sale.id) ||
            amountRest <= 0 ||
            sale.payments.filter(pay => !pay.type || (pay.type === salePayTypes.paydoc && !pay.payDoc) || !pay.amount || !pay.id).length > 0 ||
            loadingSale ||
            disabled
        ;

        const currentGuestId = guestId || (sale && sale.guestId);

        return (
            <Grid container spacing={2} className={cn(classes.container, className)} alignItems="flex-end">
                {sale.payments.map((pay, idx) => {
                    const selectedPayDoc = payDocs.find( (paydoc) => paydoc.id === pay.payDoc) || {};
                    const showCopyField = pay.linkUrl && pay.status === paymentStatus.processing;
                    const availableInBusinessUnits =
                        selectedPayDoc &&
                        selectedPayDoc.type &&
                        selectedPayDoc.type.availableInBusinessUnits ? selectedPayDoc.type.availableInBusinessUnits : [];
                    const wrongBusinessUnit = Boolean(
                        availableInBusinessUnits.length &&
                        sale.businessUnitId &&
                        availableInBusinessUnits.indexOf(sale.businessUnitId) === -1
                    );

                    if (pay.status !== paymentStatus.paid && pay.type) {
                        if (pay.type === salePayTypes.paydoc) {
                            if (selectedPayDoc.type && selectedPayDoc.type.measure === payDocMeasures.time) {
                                pay.amountTime = pay.amountTime !== null ? pay.amountTime : (selectedPayDoc ? Math.min(selectedPayDoc.balance, sale.restMultiCurrencies.PDTime) : sale.restMultiCurrencies.PDTime);
                                //pay.amount.val = null; // зачем я это написал?)
                            } else {
                                pay.amount = pay.amount.val ? pay.amount : {...sale.restMultiCurrencies.PDMoney};
                                if (pay.amountCash && pay.amount && pay.amount.cur) {
                                    pay.amountCash.cur = pay.amount.cur;
                                }
                            }
                        } else {
                            pay.amount = pay.amount.val ? pay.amount : {...sale.restMultiCurrencies[sale.usingCurrencies[0]]};
                            if (pay.amountCash && pay.amount && pay.amount.cur) {
                                pay.amountCash.cur = pay.amount.cur;
                            }
                        }
                    }

                    return (
                        <Grid item xs={12} key={idx}>
                            <Grid container alignItems={"center"}>
                                <Grid item className={cn(classes.deletePayIcon)}>
                                    <IconButton
                                        color="primary"
                                        disabled={sale.status === saleStatus.closed || (this.props.paymentType && pay.status === paymentStatus.paid)}
                                        onClick={this.handleDelPay(idx)}>
                                        <ClearIcon fontSize="small"
                                        />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={11}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <AnyField
                                                label={t('pay.type')}
                                                value={pay.type}
                                                onChange={this.handleChangePay('type', idx)}
                                                select
                                                readOnly={pay.status === paymentStatus.paid || pay.status === paymentStatus.frozen || pay.status === paymentStatus.processing || pay.status === paymentStatus.burned || disabled}
                                                style={{width: 150}}

                                            > {_.values(salePayTypes).map(payType =>
                                                <MenuItem
                                                    key={payType}
                                                    value={payType}
                                                    disabled={(availablePaymentTypes.indexOf(payType) < 0) || (onlyPaymentLinks && payType !== 'cashless')}
                                                >{t(`pay.types.${payType}`)}</MenuItem>)}
                                                {enableCheckingAccountPaymentType ?
                                                    <MenuItem
                                                        key={optionalSalePayTypes.checking_account}
                                                        value={optionalSalePayTypes.checking_account}
                                                        disabled={!hasRole("ROLE_PAYDOC_REFILL_VIA_CHECKING_ACCOUNT")}
                                                    >
                                                        {t("Checking account")}
                                                    </MenuItem>:
                                                    null
                                                }
                                            </AnyField>
                                        </Grid>
                                        { pay.type === salePayTypes.paydoc ?
                                                <Grid item>
                                                    {pay.status !== paymentStatus.paid && pay.status !== paymentStatus.frozen ?
                                                        <AnyField
                                                            label={t("Payment document")}
                                                            value={pay.payDoc}
                                                            onChange={this.handleChangePay('payDoc', idx)}
                                                            select
                                                            fullWidth
                                                            readOnly={pay.status === paymentStatus.paid || disabled}
                                                            style={{width: 200}}
                                                            error={moment(selectedPayDoc.validity).isBefore(moment()) || selectedPayDoc.statusId !== payDocStatuses.active || wrongBusinessUnit}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.inputPD,
                                                                },
                                                                endAdornment: pay.payDoc ?
                                                                        <InputAdornment position="end" className={classes.endAdornment}>
                                                                            <Link to={`/pay-docs/view/${pay.payDoc}`}><Tooltip title={t("Go to payment document card")}><ForwardIcon fontSize="small"/></Tooltip></Link>
                                                                        </InputAdornment>
                                                                    : null
                                                            }}
                                                        > {payDocs.map(item =>
                                                            <MenuItem
                                                                key={item.id}
                                                                value={item.id}
                                                                className={ (moment(item.validity).isBefore(moment()) || item.statusId !== payDocStatuses.active) ? classes.notValidPayDoc : null}
                                                                disabled={
                                                                    item.type.code === payDocTypeCodes.bonuses
                                                                    && ! sale.businessUnitBonusPaymentMaxPercent > 0
                                                                }
                                                            >{item.readableName}{item.guestId !== currentGuestId ? ' ' + item.fio : null}</MenuItem>)}
                                                        </AnyField>
                                                        :
                                                        <AnyField
                                                            label={t("Payment document")}
                                                            value={pay.payDocName}
                                                            readOnly
                                                            fullWidth
                                                            style={{width: 200}}
                                                            InputProps={{
                                                                endAdornment: pay.payDoc
                                                                    ?
                                                                        <InputAdornment position="end" className={classes.endAdornment}>
                                                                            <Link to={`/pay-docs/view/${pay.payDoc}`}><Tooltip title={t("Go to payment document card")}><ForwardIcon fontSize="small"/></Tooltip></Link>
                                                                        </InputAdornment>
                                                                    : null
                                                            }}
                                                        />
                                                    }
                                                </Grid>
                                            : null
                                        }
                                        {pay.type ?
                                                <React.Fragment>
                                                    {
                                                        (selectedPayDoc.type && selectedPayDoc.type.measure === payDocMeasures.time) ||
                                                        (pay.payDoc && pay.payDocMeasure === payDocMeasures.time) ?
                                                            <React.Fragment>
                                                                <Grid item>
                                                                    <AnyField
                                                                        label={t("pay.time")}
                                                                        value={pay.amountTime}
                                                                        onChange={this.handleChangePay('amountTime', idx)}
                                                                        readOnly={pay.status === paymentStatus.paid || disabled}
                                                                        InputProps={{
                                                                            inputComponent: NumberFormatDecimal,
                                                                        }}
                                                                        style={{width: 100}}
                                                                        onFocus={event => event.target.select()}
                                                                    />
                                                                </Grid>
                                                            </React.Fragment>
                                                            :
                                                            <Grid item>
                                                                <MoneyField
                                                                    label={t('pay.amountPay')}
                                                                    currencies={pay.type === salePayTypes.paydoc ? null : sale.usingCurrencies}
                                                                    value={pay.amount}
                                                                    onChange={this.handleChangePay('amount', idx)}
                                                                    fullWidth
                                                                    readOnly={pay.status === paymentStatus.paid || disabled}
                                                                    style={{width: 150}}
                                                                    error={pay.amount.val < 0}
                                                                    onFocus={event => event.target.select()}
                                                                />
                                                            </Grid>
                                                    }
                                                </React.Fragment>
                                            : null
                                        }
                                        {pay.type === salePayTypes.cash ?
                                            <Grid item>
                                                <MoneyField
                                                    label={t('pay.amountCash')}
                                                    value={pay.amountCash || { val: 0, cur: pay.amount.cur }}
                                                    onChange={this.handleChangePay('amountCash', idx)}
                                                    disableCurrencySelect
                                                    fullWidth
                                                    readOnly={pay.status === paymentStatus.paid || disabled}
                                                    style={{width: 150}}
                                                    error={pay.amountCash !== null && parseFloat(pay.amountCash.val) < parseFloat(pay.amount.val)}
                                                    onFocus={event => event.target.select()}
                                                />

                                            </Grid>
                                            : null
                                        }
                                        {pay.type === salePayTypes.cash ?
                                            <Grid item>
                                                <MoneyField
                                                    label={t('pay.amountCashChange')}
                                                    value={pay.amountCashChange || { val: 0, cur: pay.amount.cur }}
                                                    disableCurrencySelect
                                                    onChange={this.handleChangePay('amountCashChange', idx)}
                                                    fullWidth
                                                    readOnly={pay.status === paymentStatus.paid || disabled}
                                                    style={{width: 150}}
                                                    updatingValue={loadingCashChange}
                                                    onFocus={event => event.target.select()}
                                                    disabled
                                                />
                                            </Grid>
                                            : null
                                        }

                                        { pay.type === salePayTypes.paydoc && hasRole('ROLE_PAYDOC_PAYDOC_FREEZE') && !onlyPaymentLinks ?
                                            <ActionButton
                                                fullWidth={false}
                                                onClick={this.handleSavePayment(idx, paymentStatus.frozen)}
                                                visible={pay.status !== paymentStatus.paid}
                                                disabled={
                                                    (!pay.amount.val && !pay.amountTime) ||
                                                    processing ||
                                                    !selectedPayDoc.id ||
                                                    (selectedPayDoc.type.measure === payDocMeasures.money && parseFloat(pay.amount ? pay.amount.val : '0') > selectedPayDoc.balance) ||
                                                    ( selectedPayDoc.type.measure === payDocMeasures.time && parseFloat(pay.amountTime || '0') > selectedPayDoc.balance) ||
                                                    loadingCashChange ||
                                                    pay.status === paymentStatus.frozen ||
                                                    pay.amount.val < 0 ||
                                                    disabled ||
                                                    wrongBusinessUnit
                                                }
                                                noMargin
                                                marginLabeledFields
                                                inGridItem
                                            >{ processing ? t('pay.processing') :
                                                pay.id && pay.status === paymentStatus.frozen ?
                                                    t('Frozen') :
                                                    t('Freeze')
                                            }</ActionButton>
                                            : null
                                        }

                                        {showCopyField &&
                                            <Grid item>
                                                <CopyField
                                                    label={t("pay.url")}
                                                    value={pay.linkUrl}
                                                    fullWidth={false}
                                                />
                                            </Grid>
                                        }

                                        {pay.type === salePayTypes.cashless && sale.hasPaymentLinks && !disablePaymentLinks ?
                                            <Grid item>
                                                <ActionButton
                                                    fullWidth={false}
                                                    onClick={this.handleSavePayment(idx, paymentStatus.processing)}
                                                    visible={ pay.status === paymentStatus.processing || pay.status !== paymentStatus.paid }
                                                    disabled={ pay.status === paymentStatus.processing || pay.status === paymentStatus.paid }
                                                    noMargin
                                                    marginLabeledFields
                                                    inGridItem
                                                >{ pay.status === paymentStatus.processing ? t('Ожидается оплата') : t('Создать ссылку для оплаты') }</ActionButton>
                                            </Grid>
                                            : null
                                        }

                                        { pay.type === optionalSalePayTypes.checking_account ?
                                            <React.Fragment>
                                                <Grid item xs={4}>
                                                    <DateField2
                                                        label={t("Date arrive in checking account")}
                                                        name={"arrivedInCheckingAccountAt"}
                                                        value={pay.arrivedInCheckingAccountAt}
                                                        onChange={this.handleChangePay('arrivedInCheckingAccountAt', idx)}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <DateField2
                                                        label={t("Date registration in ERP")}
                                                        name={"registeredInErpAt"}
                                                        value={pay.registeredInErpAt}
                                                        onChange={this.handleChangePay('registeredInErpAt', idx)}
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <AnyField
                                                        label={t("Juridical person")}
                                                        select
                                                        fullWidth
                                                        name={'juridicalPersonId'}
                                                        value={pay.juridicalPersonId}
                                                        onChange={this.handleChangePay('juridicalPersonId', idx)}
                                                    >
                                                        {this.state.juridicalPersons.filter(jp => {
                                                                const { businessUnits, sale } = this.state
                                                                const bu = businessUnits.find(p => p.id === sale.businessUnitId)
                                                                if (bu) {
                                                                    return bu.juridicalPersonId === jp.id || bu.trustedJuridicalPersons.indexOf(jp.id) > -1
                                                                } else {
                                                                    return false
                                                                }
                                                            }
                                                        ).map(
                                                            jp =>
                                                                <MenuItem key={jp.id} value={jp.id}>
                                                                    {jp.shortName}
                                                                </MenuItem>)}
                                                    </AnyField>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <AnyField
                                                        label={t("Checking account")}
                                                        select
                                                        fullWidth
                                                        name={'checkingAccountId'}
                                                        value={pay.checkingAccountId}
                                                        onChange={this.handleChangePay('checkingAccountId', idx)}
                                                    >
                                                        {checkingAccounts
                                                            .filter(ca => ca.juridicalPersonId === pay.juridicalPersonId)
                                                            .map(
                                                                ca =>
                                                                    <MenuItem key={ca.id} value={ca.id}>
                                                                        {ca.bankName}/
                                                                        {ca.currency}/
                                                                        {juridicalPersons.find(p => p.id === ca.juridicalPersonId) ?
                                                                            juridicalPersons.find(p => p.id === ca.juridicalPersonId).shortName:
                                                                            null}
                                                                    </MenuItem>
                                                            )}
                                                    </AnyField>
                                                </Grid>
                                            </React.Fragment>:
                                            null
                                        }

                                        {showCopyField && pay.messengers.length && hasRole('ROLE_SALE_PAYMENTLINK_SEND') ?
                                        <Grid item>
                                            <MultiSelectFromItemsField
                                                label={t('pay.sendLink')}
                                                items={pay.messengers}
                                                value={pay.sendToMessengers}
                                                onChange={this.handleChangePay('sendToMessengers', idx)}
                                                className={classes.filterControl}
                                                valueField="type"
                                                textField="name"
                                                emptySelectedLabel={t('pay.choose')}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Copy"
                                                            onClick={this.sendPaymentLink(idx)}
                                                        >
                                                            <SendIcon/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                }}
                                            />
                                        </Grid>
                                            : null
                                        }

                                        {this.props.customBonusPayDocGuest && sale.businessUnitBonusesEnabled ?
                                            <Grid item>
                                                <GuestField
                                                    label={t("Guest for bonus crediting")}
                                                    value={pay.customBonusPayDocGuestId}
                                                    fullWidth
                                                    onChange={this.handleChangePay("customBonusPayDocGuestId", idx)}
                                                />
                                            </Grid>
                                            :
                                            null
                                        }

                                        <ActionButton
                                            fullWidth={false}
                                            onClick={this.handlePayPayment(idx, pay)}
                                            visible={pay.status !== paymentStatus.paid && pay.status !== paymentStatus.processing && !onlyPaymentLinks}
                                            disabled={
                                                !pay.type ||
                                                (!pay.amount.val && !pay.amountTime) ||
                                                processing ||
                                                ( pay.type === salePayTypes.cash &&  parseFloat( pay.amountCash ? pay.amountCash.val : '0') < parseFloat(pay.amount ? pay.amount.val : '0') ) ||
                                                ( pay.type === salePayTypes.paydoc && (!pay.payDoc || wrongBusinessUnit)) ||
                                                ( pay.type === salePayTypes.paydoc && pay.amount.val < 0) ||
                                                ( pay.type === optionalSalePayTypes.checking_account && (pay.amount.val < 0 || !pay.checkingAccountId || !pay.arrivedInCheckingAccountAt || !pay.registeredInErpAt)) ||
                                                loadingCashChange ||
                                                (disabled && !possibilityPayment)
                                            }
                                            noMargin
                                            marginLabeledFields
                                            inGridItem
                                        >{ processing ? t('pay.processing') : t('pay.pay') }</ActionButton>
                                        {wrongBusinessUnit ? <Grid item className={classes.notValidPayDoc}>{t("You cannot use the selected PD for payment in this salon")}</Grid> : null}
                                    </Grid>
                                </Grid>
                        </Grid>
                    </Grid>
                    )
                })}

                <ActionButton
                    noMargin
                    onClick={this.handleAddPay}
                    fullWidth={false}
                    disabled={disableAdd}
                    processing={loadingSale}
                    inGridItem
                    marginLabeledFields={false}
                >{ buttonLabel }</ActionButton>
                <Grid item><Typography>{t('pay.rest')}: { this.showCurrentRest() }</Typography></Grid>
                {this.renderPaymentBusinessUnitModal()}
                {askPinDialogOpen ? <PayDocAskPinDialog onClose={this.handleAskPinDialogClosed}/> : null}
            </Grid>
        );
    };

    renderPaymentBusinessUnitModal = () => {
        const { t } = this.props;
        const { businessUnits, sale, showPaymentBusinessUnitModal } = this.state;
        return (
            <Dialog
                open={showPaymentBusinessUnitModal}
                onClose={this.handleClosePaymentBusinessUnitModal(false)}
                maxWidth="sm"
            >
                <DialogTitle>{t('pay.paymentBusinessUnitModalTitle')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <p>{t('pay.paymentBusinessUnitModalDesc')}</p>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectFromItemsField
                                items={businessUnits}
                                label={t("Business unit")}
                                value={sale.paymentBusinessUnitId}
                                onChange={this.handleChangePaymentBusinessUnitModal}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleClosePaymentBusinessUnitModal(true)}
                        disabled={!sale.paymentBusinessUnitId}
                        color="primary"
                    >{t('Continue')}</Button>
                    <Button onClick={this.handleClosePaymentBusinessUnitModal(false)} color="primary">{t("Cancel")}</Button>
                </DialogActions>
            </Dialog>
        )
    };

    handleChangePaymentBusinessUnitModal = (event) => {
        this.setState({
            sale: {
                ...this.state.sale,
                paymentBusinessUnitId: event.target.value
            }
        })
    }

    handleClosePaymentBusinessUnitModal = (ok) => () => {
        if (!ok) {
            this.setState({
                showPaymentBusinessUnitModal: false,
                sale: {
                    ...this.state.sale,
                    paymentBusinessUnitId: null,
                    handleSavePaymentArguments: []
                }
            });
            return;
        }

        let idx = this.state.handleSavePaymentArguments[0];
        let status = this.state.handleSavePaymentArguments[1];

        this.setState({
            showPaymentBusinessUnitModal: false,
            handleSavePaymentArguments: []
        });

        this.handleSavePayment(idx, status)();

    }
}
