import baseRequest from "./baseReuqest";
import {emptyMoney, zeroMoney} from "./common";
import moment from "moment";
import {Link} from "react-router-dom";
import React from "react";
import * as queryString from "query-string-object";

export const paymentTypes = {
    cash: 'cash',
    cashless: 'cashless',
    checkingAccount: 'checking_account',
}

export function getSale(id) {
    return baseRequest(`/api/sale/id/${id}`)
}

export function savePayment(sale) {
    return baseRequest( `/api/sale/payment`, {
        method: 'PUT',
        body: JSON.stringify(sale)
    })
}

export function deletePayment(id) {
    return baseRequest( `/api/sale/payment/delete/${id}`, {
        method: 'DELETE'
    })
}

export function deletePayDocRefill(id) {
    return baseRequest(`/api/pay-doc/recharge/delete/${id}`, {
        method: 'DELETE'
    })
}

export function getPaymentTypes(withCheckingAccount = false) {
    let response = baseRequest(`/api/sale/payment-types`)
        .then(response => {
            if (!withCheckingAccount) {
                response.data = response.data.filter(type => type.id !== 'checking_account')
            }
            return response
        })

    return response
}

export function getDiscountGroups(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let url = '/api/sale/discount-groups';
    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function createDiscountGroup(region) {
    return baseRequest('/api/sale/save-discount-group', {
        method: 'POST',
        body: JSON.stringify({...region}),
    });
}

export const paymentStatus = {
    new: 'new',
    paid: 'paid',
    frozen: 'frozen',
    processing: 'processing',
    burned: 'burned',
};

export const emptyPay = {
    type: null,
    payDoc: null,
    amount: {...emptyMoney},
    amountTime: null,
    status: paymentStatus.new,
    amountCash: {...zeroMoney},
    linkUrl: null,
    messengers: [],
    sendToMessengers: [],
    arrivedInCheckingAccountAt: null,
    registeredInErpAt: null,
    checkingAccountId: null
};


export const salePayTypes =  {
    /** Наличные */
    cash: 'cash',

    /** Безналичные */
    cashless: 'cashless',

    /** Платежный документ */
    paydoc: 'paydoc',
};

export const optionalSalePayTypes = {
    /** Расчётный счёт */
    checking_account: 'checking_account'
}

export const saleStatus = {
    /**Закрыт*/
    closed: 'closed',
};

export function convertToMainCurrency(businessUnitId, amount) {
    return baseRequest('/api/sale/convert-to-main', {
        method: 'POST',
        body: {businessUnitId, amount},
    })
}

export function calculateCashChange(businessUnitId, amount) {
    return baseRequest('/api/sale/calculate-cash-change', {
        method: 'POST',
        body: {businessUnitId, amount},
    })
}

const dateFormat = 'YYYY-MM-DD';

export function processSearchDates(search) {
    if (moment.isMoment(search.date_start)) search.date_start = search.date_start.format(dateFormat);
    if (moment.isMoment(search.date_end)) search.date_end = search.date_end.format(dateFormat);
}

export function getSales(search, page, per_page, order, order_by) {
    processSearchDates(search);

    return baseRequest('/api/sale', {
        query: {search, page, per_page, order, order_by},
    });
}

export function getSalableEntityClasses() {
    return baseRequest('/api/sale/entity-class');
}

export function getDiscountNumberTypes() {
    return baseRequest('/api/sale/discount-number-type');
}

export function getSaleHistoryChange(id, order, order_by) {
    return baseRequest(`/api/sale/history-change/${id}`, {
        query: {id, order, order_by},
    });
}

export function getPaymentHistoryChange(id, order, order_by) {
    return baseRequest(`/api/payment/history-change/by-sale/${id}`, {
        query: {id, order, order_by},
    });
}

export function sendPaymentLink(paymentId, messengers) {
    return baseRequest('/api/payment-link/send', {
        method: 'POST',
        body: {paymentId, messengers},
    })
}

export const salableEntityClasses = {
    visit: 'Visit',
    goodStorageSale: 'GoodStorageSale',
    payDoc: 'PayDoc',
    payDocRefill: 'PayDocRefill',
    payDocRefundDeal: 'PayDocRefundDeal',
};

export function getSalableEntityLink(seClass, seId) {
    switch (seClass) {
        case salableEntityClasses.visit:
            return `/orders/view/${seId}`;
        case salableEntityClasses.goodStorageSale:
            return `/goods/sell/view/${seId}`;
        case salableEntityClasses.payDoc:
            return `/pay-docs/view/${seId}`;
        case salableEntityClasses.payDocRefill:
            return `/pay-docs/refill/${seId}`;
        case salableEntityClasses.payDocRefundDeal:
            return `/money-flow/deal/id/${seId}`;

        default:
            return null;
    }
}

const processSalableEntity = value => {
    if (Array.isArray(value)) {
        return value.map((item, idx) => {
            return <span key={idx}><Link
                to={getSalableEntityLink(item.entity, item.id)}
            >{item.entityText} - {item.totalFormatted}</Link></span>;
        })
    }

    return value;
};

const processPayments = value => {
    if (Array.isArray(value)) {
        return value.map((item, idx) =>
            <div key={idx}>{item.statusText}: {item.typeText}{item.type === 'paydoc' ? <span> <Link to={`/pay-docs/view/${item.payDoc}`}>{item.payDocName}</Link></span> : null} - {item.amountFormatted}</div>
        );
    }

    return value;
}

export const columnDataSales = [
    { id: 'createdAt', label: 'Date', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
    { id: 'businessUnitName', label: 'Business unit' },
    { id: 'totalFormatted', label: 'Total' },
    { id: 'statusText', label: 'Status'},
    { id: 'salableEntity', label: 'salableEntity', processValue: processSalableEntity},
    { id: 'payments', label: 'payments', processValue: processPayments},
    { id: 'guest.name', label: 'Guest'},
    { id: 'creatorName', label: 'User' },
];

export function getDiscountHandlers() {
    return baseRequest('/api/sale/discount/handler');
}

export function calculateSale(sale) {
    return baseRequest('/api/sale/calculate', {
        method: "POST",
        body: sale,
    })
}

export function getPersonalDiscountNames() {
    return baseRequest('/api/sale/personal-discount-names');
}

export function createAndClosePayDocsSale(guestId, payDocIds) {
    return baseRequest('/api/sale/create-and-close-pay-docs-sale', {
        method: "POST",
        body: {guestId, payDocIds}
    })
}

export function createSaleOfEqualPayDocsRefills(data) {
    return baseRequest("/api/sale/sale-of-equal-pay-docs-refills",
        {
            method: "POST",
            body: data
        })
}

export function updateSaleOfEqualPayDocsRefills(data) {
    return baseRequest(`/api/sale/sale-of-equal-pay-docs-refills/` + data.saleId,
        {
            method: "PUT",
            body: data
        })
}

export function closeSaleOfEqualPayDocsRefills(saleId) {
    return baseRequest("/api/sale/close-sale-of-equal-pay-docs-refills",
        {
            method: "POST",
            body: {saleId}
        })
}

export function removeSaleOfEqualPayDocsRefills(saleId) {
    return baseRequest(`/api/sale/sale-of-equal-pay-docs-refills/${saleId}`, {
        method: 'DELETE',
    });
}

export function massCreateAndSetPersonalDiscount(data) {
    return baseRequest("/api/sale/mass-create-and-set-personal-discount",
        {
            method: "POST",
            body: data
        })
}

export function patchSale(saleId, fields) {
    return baseRequest(`/api/sale/${saleId}`, {
        method: 'PATCH',
        body: fields,
    })
}